<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Shipping manifests')"
            text-class="text-h6"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="toggleFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn
            v-if="!isClient"
            color="light-blue-9"
            text-color="white"
            icon="add"
            size="sm"
            no-caps
            unelevated
            @click="createConsumption"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filter-collapse
            :is-open="isOpenFilter"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: states
            }
          }"
            @submit="handleFiltersSubmit"
            @close="toggleFilters"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="consumptions"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="consumptionsLoading"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
                @dblclick="onRowClick(props.row)"
            >
              <q-td
                  key="number"
                  :props="props"
                  auto-width
              >
                {{ props.rowIndex + 1 }}
              </q-td>

              <q-td
                  key="deliveryServiceLogo"
                  :props="props"
              >
                <img
                    v-if="props.row._embedded.deliveryService.logo"
                    :src="appOptions.defaultServer + props.row._embedded.deliveryService.logo"
                    style="width: 100px; height: 80px; object-fit: contain;"
                >

                <strong v-else>{{ props.row._embedded.deliveryService.name }}</strong>
              </q-td>

              <q-td
                  key="id"
                  :props="props"
              >
                <strong>{{ props.row.id }}</strong>

                <br>

                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                  key="state"
                  :props="props"
              >
                <q-badge
                    :color="stateColors[props.row.state]"
                    text-color="dark"
                    class="q-pa-sm"
                >
                  {{ $t(props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                  key="info"
                  :props="props"
              >
                <strong>{{ props.row._embedded.sender.name }} ({{ props.row._embedded.sender.id }})</strong>

                <br>

                {{ props.row.comment }}
              </q-td>

              <q-td
                key="extId"
                :props="props"
                >
                <strong> {{ props.row.extId }}</strong>
              </q-td>

              <q-td
                  key="shippingDate"
                  :props="props"
              >
                <div v-if="props.row.shippingDate">
                  {{
                    $moment(typeof props.row.created === 'object' ? props.row.shippingDate.date : props.row.shippingDate).format(appOptions.formats.date)
                  }}
                </div>
              </q-td>

              <q-td
                  key="comment"
                  :props="props"
                  style="max-width: 250px; white-space: pre-wrap !important;"
              >
                {{ props.row.comment || '' }}
              </q-td>

              <q-td
                  key="deliveryRequests"
                  :props="props"
              >
                {{ props.row.deliveryRequests }}

                <span
                    v-if="props.row.state === 'export_errors'"
                    :class="'q-px-sm q-py-xs rounded bg-' + stateColors[props.row.state]"
                >
                  1
                </span>
              </q-td>

              <q-td
                  key="actions"
                  :props="props"
                  style="max-width: 65px; white-space: pre-wrap !important;"
              >
                <div class="row items-center justify-center q-mt-xs">
                  <q-btn
                      v-if="props.row.state !== 'closed'"
                      color="light-blue-9"
                      text-color="white"
                      :label="$t('Export')"
                      size="sm"
                      no-caps
                      unelevated
                      @click="exportConsumption(props.row)"
                  />
                </div>
                <div class="row items-center justify-center q-mt-xs">
                  <action-lazy-dropdown :option="options(props)"/>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import ActionLazyDropdown from '@/apps/app/components/action-header/ActionLazyDropdown'

export default {
  name: 'DMConsumptions',
  components: {
    FilterCollapse,
    ActionLazyDropdown
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpenFilter: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          name: 'deliveryServiceLogo',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Info'),
          name: 'info',
          align: 'left'
        },
        {
          label: this.$t('ExtId'),
          name: 'extId',
          align: 'left'
        },
        {
          label: this.$t('Ship date'),
          name: 'shippingDate',
          align: 'left'
        },
        {
          label: this.$t('Comment'),
          name: 'comment',
          align: 'left'
        },
        {
          label: this.$t('Shipping requests'),
          name: 'deliveryRequests',
          align: 'left'
        },
        {
          name: 'actions',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'grey-3',
        checking: 'purple',
        locked: 'yellow',
        confirmed: 'warning',
        export_errors: 'danger',
        closed: 'success'
      },
      activatedFields: [
        'id',
        'sender',
        'state',
        'deliveryService'
      ],
      states: [
        { id: 'new', title: 'New' },
        { id: 'checking', title: 'Checking' },
        { id: 'locked', title: 'Locked' },
        { id: 'confirmed', title: 'Confirmed' },
        { id: 'export_errors', title: 'Export_errors' },
        { id: 'closed', title: 'Closed' }
      ],
      entityClass: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Consumption'
    }
  },
  computed: {
    ...mapGetters([
      'consumptions',
      'totalConsumptionsNumber',
      'consumptionsLoading',
      'appOptions',
      'isClient'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.totalConsumptionsNumber)
          : this.$t('Filter')
    },
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'saveConsumptionByPayload',
      'loadConsumptionDocument',
      'loadConsumptions'
    ]),
    ...mapMutations([
      'setConsumption',
      'setConsumptions',
      'addErrorNotification'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      return this.onRequest({ pagination: { page: 1, filter } })
    },
    toggleFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadConsumptions(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    exportConsumption (data) {
      this.saveConsumptionByPayload({
        data: { state: 'closed' },
        id: data.id
      })
          .then(() => {
            const newConsumptions = this.consumptions.map(item => {
              return item.id === data.id
                  ? { ...item, state: 'closed' }
                  : item
            })

            this.setConsumptions(newConsumptions)
            this.loadConsumptionDocument(data.id)
          })
    },
    consumptionDocs (data) {
      this.loadConsumptionDocument(data.id)
    },
    onRowClick (row) {
      this.setConsumption(row)
      this.$router.push('/documents/shipping-manifests/entity/' + row.id)
    },
    createConsumption () {
      this.$router.push('/documents/shipping-manifests/entity/')
    },
    options (data) {
      return {
        color: 'light-blue-9',
        options: [],
        onScroll: (search, page) => {
          const query = {
            search,
            page,
            filter: [
              { type: 'eq', field: 'state', value: 'active' },
              { type: 'eq', field: 'entityClass', value: this.entityClass },
              {
                type: 'jsonb',
                property: 'conditions',
                field: 'delivery-services-carrier',
                condition: 'eq',
                value: data.row._embedded?.deliveryService?.id
              }
            ]
          }

          return this.$service.documentTemplate.getAll(query)
        },
        onClick: (item) => {
          const url = data.row._links.self.href.split('api')[1]

          if (item.id === 'default') {
            const data = { url: url, id: data.row.id }
            return this.downloadAcceptancePDF(data)
                .catch(error => {
                  this.addErrorNotification(error)
                })
          }

          return this.$service.acceptance.getTemplate(url, item.id)
              .then(data => {
                this.$service.printer.print(data, undefined, true)
              })
              .catch(error => {
                console.log(error)
                this.addErrorNotification(error)
              })
        }
      }
    }
  }
}
</script>
